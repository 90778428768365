import React, { FormEvent } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import PinIcon from "../../ParticipantInfo/PinIcon/PinIcon";
import AvatarIcon from "../../../icons/AvatarIcon";

import Typography from "@material-ui/core/Typography";
import SelectParticipantPinIcon from "./PinIcon";

const BORDER_SIZE = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // position: "relative",
      display: "flex",
      alignItems: "center",
      //height: 0,
      //overflow: "hidden",
      //marginBottom: "2em",
      "& video": {
        filter: "none",
        objectFit: "contain !important"
      },
      borderRadius: "4px",
      border: `${BORDER_SIZE}px solid rgb(245, 248, 255)`,
      //paddingTop: `calc(${(9 / 16) * 100}% - ${BORDER_SIZE}px)`,
      //background: "black",
      [theme.breakpoints.down("sm")]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: "8px",
        marginBottom: "0",
        fontSize: "10px"
        // paddingTop: `${theme.sidebarMobileHeight - 2}px`
      }
    },
    innerContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    infoContainer: {
      // position: "absolute",
      zIndex: 2,
      display: "flex",
      flexDirection: "row",
      //justifyContent: "space-between",
      height: "100%",
      width: "100%",
      background: "transparent",
      top: 0
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //background: "black",
      alignSelf: "flex-end",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        "& svg": {
          transform: "scale(0.5)"
        }
      }
    },

    identity: {
      //background: "rgba(0, 0, 0, 0.5)",
      //color: "white",
      padding: "0.18em 0.3em",
      margin: 0,
      display: "flex",
      alignItems: "center"
    },
    infoRowBottom: {
      display: "flex",
      justifyContent: "space-between",
      // position: "absolute",
      bottom: 0,
      left: 0
    },

    typeography: {
      color: "black",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem"
      }
    },
    hideParticipant: {
      display: "none"
    },
    cursorPointer: {
      cursor: "pointer"
    }
  })
);

interface SelectParticipantInfoProps {
  participant: string;
  onClick?: () => void;
  isSelected?: boolean;
}

export default function SelectParticipantInfo({
  participant,
  onClick,
  isSelected
}: SelectParticipantInfoProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.cursorPointer]: Boolean(onClick)
      })}
      onClick={onClick}
      data-cy-participant={participant}
    >
      <div className={classes.infoContainer}>
        <div className={classes.avatarContainer}>
          <AvatarIcon />
        </div>
        <div className={classes.infoRowBottom}>
          <span className={classes.identity}>
            <Typography
              variant="body1"
              className={classes.typeography}
              component="span"
            >
              {participant}
            </Typography>
          </span>
        </div>

        {<div>{isSelected && <SelectParticipantPinIcon />}</div>}
      </div>
      {/*<div className={classes.innerContainer}>*/}
      {/*  <div className={classes.avatarContainer}>*/}
      {/*    <AvatarIcon />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}
