import React from "react";

export default function VideoLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      viewBox="0 0 512 512"
    >
      <g fillRule="evenodd">
        <path
          fill="#FFF"
          d="M 229.7387 0 h 43.3706 c 61.0803 0 97.2382 9.1141 129.2476 26.2424 c 32.0147 17.1125 57.1361 42.234 74.2487 74.2487 C 493.7339 132.4952 502.848 168.6479 502.848 229.7387 v 43.3706 c 0 61.0803 -9.1141 97.2382 -26.2424 129.2476 c -17.1125 32.0147 -42.234 57.1361 -74.2487 74.2487 C 370.3476 493.7339 334.1896 502.848 273.1093 502.848 h -43.3706 c -61.0803 0 -97.2382 -9.1141 -129.2476 -26.2424 c -32.0147 -17.1125 -57.1361 -42.234 -74.2487 -74.2487 C 9.1141 370.3528 0 334.2001 0 273.1093 v -43.3706 c 0 -61.0803 9.1141 -97.2382 26.2424 -129.2476 C 43.3549 68.4764 68.4764 43.3549 100.491 26.2424 C 132.4952 9.1141 168.6479 0 229.7387 0 z"
        />

        <path
          fill="#CE0E2D"
          d="M370.5,350.2c-0.1,8.4-6.7,15.2-14.9,15.2s-14.9-6.8-14.9-15.2h0V161.8h0c0.1-8.4,6.7-15.2,14.9-15.2
	s14.9,6.8,14.9,15.2h0L370.5,350.2L370.5,350.2z M305.8,365.4c-8.2,0-14.9-6.8-14.9-15.2h0v-86.5h0c0-0.1,0-0.2,0-0.2
	c0-8.4,6.7-15.3,14.9-15.3c8.2,0,14.9,6.8,14.9,15.3c0,0.1,0,0.2,0,0.2h0v86.5h0C320.6,358.6,314,365.4,305.8,365.4z M256,365.4
	c-8.3,0-15-6.8-15-15.2h0V212.9h0c0,0,0-0.1,0-0.1c0-8.4,6.7-15.2,15-15.2c8.3,0,15,6.8,15,15.2c0,0.1,0,0.1,0,0.1h0v137.4h0
	C271,358.6,264.3,365.4,256,365.4z M206.2,365.4c-8.2,0-14.9-6.8-14.9-15.2h0V161.8h0c0.1-8.4,6.7-15.2,14.9-15.2
	c8.2,0,14.9,6.8,14.9,15.2h0v188.5h0C221.1,358.6,214.4,365.4,206.2,365.4z M156.4,365.4c-8.2,0-14.9-6.8-14.9-15.2h0v-86.5h0
	c0-0.1,0-0.2,0-0.2c0-8.4,6.7-15.3,14.9-15.3s14.9,6.8,14.9,15.3c0,0.1,0,0.2,0,0.2h0v86.5h0C171.3,358.6,164.6,365.4,156.4,365.4z
	 M156.4,227.9c-8.2,0-14.9-6.8-14.9-15.2c0-8.4,6.7-15.2,14.9-15.2s14.9,6.8,14.9,15.2C171.4,221.1,164.7,227.9,156.4,227.9z"
        />
      </g>
    </svg>
  );
}
