import React, { ChangeEvent, useState, FormEvent } from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useAppState } from "../../../state";
import { verifyCode } from "../../../state/useMeetcodeAuth/useMeetcodeAuth";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em"
    }
  },
  passcodeContainer: {
    minHeight: "120px"
  },
  textFieldContainer: {
    width: "100%"
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

interface MeetCodeScreenProps {
  roomName: string;

  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function MeetCodeScreen({
  roomName,
  setRoomName,
  handleSubmit
}: MeetCodeScreenProps) {
  const classes = useStyles();
  const { user, signIn } = useAppState();
  const [authError, setAuthError] = useState<Error | null>(null);
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [meetcode, setMeetcode] = useState("");

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const login = (e: FormEvent<HTMLFormElement>) => {
    setIsAuthInProgress(true);
    setAuthError(null);
    signIn
      ?.call(meetcode)
      .then(() => {
        setIsAuthInProgress(false);
        handleSubmit(e);
        //history.replace(location?.state?.from || { pathname: "/" });
      })
      .catch(err => {
        setAuthError(err);
        setIsAuthInProgress(false);
      });
  };

  const internalSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(e);
  };

  //const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Enter meetcode to join a room
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container justify="space-between">
          <div className={classes.inputContainer}>
            <InputLabel shrink htmlFor="input-meetcode">
              Meeting code
            </InputLabel>
            <TextField
              id="input-meetcode"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMeetcode(e.target.value)
              }
              type="text"
              variant="outlined"
              size="small"
            />
            <div>
              {authError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {authError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!meetcode.length}
            className={classes.continueButton}
          >
            Submit
          </Button>
        </Grid>
      </form>
    </>
  );
}
