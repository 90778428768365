import React, { useState } from "react";
import clsx from "clsx";
import SelectParticipant from "./SelectParticipantInfo";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppState } from "../../../state";
import { useSelectedParticipant } from "./SelectedParticipantContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "2em",
      overflowY: "auto",
      overflowX: "initial",
      background: "transparent",
      gridArea: "1 / 1 / 1 / 1",
      zIndex: 5,

      boxSizing: "border-box",

      [theme.breakpoints.down("sm")]: {
        gridArea: "1 / 1 / 1 / 1",
        overflowY: "initial",
        overflowX: "auto",
        display: "flex",
        padding: "8px"
      }
    },
    transparentBackground: {
      background: "transparent"
    },
    scrollContainer: {
      boxSizing: "border-box",
      display: "block",
      overflowY: "auto",
      overflowX: "initial",
      height: "180px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",

        overflowY: "initial",
        overflowX: "auto"
      }
    }
  })
);

export default function SelectParticipantList() {
  const classes = useStyles();

  const { user, signIn, participants } = useAppState();
  const [
    selectedParticipant,
    setSelectedParticipant
  ] = useSelectedParticipant();
  //var setSelectedParticipant = function(participant: string) {};

  if (!participants || participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    // <aside
    //   className={clsx(classes.container, {
    //     [classes.transparentBackground]: true,
    //   })}
    // >
    <div className={classes.scrollContainer}>
      {/*<SelectParticipant participant={participants}  />*/}
      {participants.map(participant => {
        return (
          <SelectParticipant
            key={participant}
            participant={participant}
            isSelected={participant == selectedParticipant}
            onClick={() => setSelectedParticipant(participant)}
          />
        );
      })}
    </div>
    // </aside>
  );
}
