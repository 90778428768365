import React, { useState, useEffect, FormEvent } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import PreflightTest from "./PreflightTest/PreflightTest";
import RoomNameScreen from "./RoomNameScreen/RoomNameScreen";
import MeetCodeScreen from "./MeetCodeScreen/MeetCodeScreen";
import { useAppState } from "../../state";
import { useParams } from "react-router-dom";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import Video from "twilio-video";
import SelectParticipantScreen from "./SelectParticipantScreen/SelectParticipantScreen";
import { SelectParticipantProvider } from "./SelectParticipantScreen/SelectedParticipantContext";

export enum Steps {
  meetCodeStep,
  roomNameStep,
  selectParticipantStep,
  deviceSelectionStep
}

export default function PreJoinScreens() {
  const { user, setUser, participants, roomType } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, URLIdentity } = useParams();
  const [step, setStepInternal] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || "");
  //const [roomName, setRoomName] = useState<string>("");

  const [mediaError, setMediaError] = useState<Error>();

  const setRoomName = function(name: string) {
    if (!user) {
      setUser({ roomName: name });
    } else {
      var rn = { roomName: name };
      setUser({ ...user, ...rn });
    }
  };
  const setStep = function(step: Steps) {
    console.log("setstep " + step);
    setStepInternal(step);
  };
  useEffect(() => {
    if (process.env.REACT_APP_SET_AUTH === "meetcode") {
      if (user?.roomName && participants && !user?.displayName) {
        if (participants.length > 1) {
          setStep(Steps.selectParticipantStep);
        } else if (user?.roomName && step != Steps.deviceSelectionStep) {
          setRoomName(user?.roomName);
          setName(user?.displayName || "");
          setStep(Steps.deviceSelectionStep);
        }
      } else if (user?.roomName && step != Steps.deviceSelectionStep) {
        setRoomName(user?.roomName);
        setName(user?.displayName || "");
        setStep(Steps.deviceSelectionStep);
      }
    } else {
      if (URLIdentity) {
        console.log("identity> " + URLIdentity);
      }
      if (URLRoomName) {
        setRoomName(URLRoomName);
        if (user?.displayName) {
          setStep(Steps.deviceSelectionStep);
        }
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep) {
      getAudioAndVideoTracks().catch(error => {
        console.log("Error acquiring local media:");
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    //{//if (!window.location.origin.includes("twil.io")) {
    //  window.history.replaceState(
    //    null,
    //    "",
    //    window.encodeURI(`/room/${roomName}${window.location.search || ""}`)
    //  );
    //}
    // if (user?.roomName && participants && !(user?.displayName))
    // {
    //   console.log("selectParticipantStep");
    //   setStep(Steps.selectParticipantStep);
    // }
    // else
    // {
    //   console.log("deviceSelectionStep");
    //   setStep(Steps.deviceSelectionStep);
    // }
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer
      subContent={step === Steps.deviceSelectionStep && SubContent}
    >
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={user?.roomName || ""}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.meetCodeStep && (
        <MeetCodeScreen
          roomName={user?.roomName || ""}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.selectParticipantStep && (
        <SelectParticipantProvider>
          <SelectParticipantScreen handleSubmit={handleSubmit} />
        </SelectParticipantProvider>
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={name}
          roomName={user?.roomName || ""}
          setStep={setStep}
          code={user?.meetcode}
        />
      )}
    </IntroContainer>
  );
}
