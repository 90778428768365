import React, { SVGProps } from "react";

export default function AppLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40px"
      height="40px"
      viewBox="0 0 512 512"
      {...props}
    >
      <g fill="#CE0E2D" fillRule="evenodd">
        <path
          d="M370.5,350.2c-0.1,8.4-6.7,15.2-14.9,15.2s-14.9-6.8-14.9-15.2h0V161.8h0c0.1-8.4,6.7-15.2,14.9-15.2
	s14.9,6.8,14.9,15.2h0L370.5,350.2L370.5,350.2z M305.8,365.4c-8.2,0-14.9-6.8-14.9-15.2h0v-86.5h0c0-0.1,0-0.2,0-0.2
	c0-8.4,6.7-15.3,14.9-15.3c8.2,0,14.9,6.8,14.9,15.3c0,0.1,0,0.2,0,0.2h0v86.5h0C320.6,358.6,314,365.4,305.8,365.4z M256,365.4
	c-8.3,0-15-6.8-15-15.2h0V212.9h0c0,0,0-0.1,0-0.1c0-8.4,6.7-15.2,15-15.2c8.3,0,15,6.8,15,15.2c0,0.1,0,0.1,0,0.1h0v137.4h0
	C271,358.6,264.3,365.4,256,365.4z M206.2,365.4c-8.2,0-14.9-6.8-14.9-15.2h0V161.8h0c0.1-8.4,6.7-15.2,14.9-15.2
	c8.2,0,14.9,6.8,14.9,15.2h0v188.5h0C221.1,358.6,214.4,365.4,206.2,365.4z M156.4,365.4c-8.2,0-14.9-6.8-14.9-15.2h0v-86.5h0
	c0-0.1,0-0.2,0-0.2c0-8.4,6.7-15.3,14.9-15.3s14.9,6.8,14.9,15.3c0,0.1,0,0.2,0,0.2h0v86.5h0C171.3,358.6,164.6,365.4,156.4,365.4z
	 M156.4,227.9c-8.2,0-14.9-6.8-14.9-15.2c0-8.4,6.7-15.2,14.9-15.2s14.9,6.8,14.9,15.2C171.4,221.1,164.7,227.9,156.4,227.9z"
        />
      </g>
    </svg>
  );
}
