import React, {
  ChangeEvent,
  useState,
  FormEvent,
  createContext,
  useContext
} from "react";
import { Participant } from "twilio-video";

type selectedParticipantContextType = [
  string | undefined,
  React.Dispatch<React.SetStateAction<string>>
];

const SelectParticipantContext = createContext<selectedParticipantContextType>(
  null!
);

export function SelectParticipantProvider(props: React.PropsWithChildren<{}>) {
  const [selectedParticipant, setSelectedParticipant] = useState("");
  return (
    <SelectParticipantContext.Provider
      value={[selectedParticipant, setSelectedParticipant]}
    >
      {props.children}
    </SelectParticipantContext.Provider>
  );
}

export function useSelectedParticipant() {
  const context = useContext(SelectParticipantContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
