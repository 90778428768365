import React, { ChangeEvent, useState, FormEvent } from "react";

import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useAppState } from "../../../state";
import { verifyCode } from "../../../state/useMeetcodeAuth/useMeetcodeAuth";
import SelectParticipantList from "./SelectParticipantList";
import {
  SelectParticipantProvider,
  useSelectedParticipant
} from "./SelectedParticipantContext";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em"
  },
  inputContainer: {
    display: "flex",
    //justifyContent: "space-between",
    alignItems: "center",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

interface SelectParticipantScreenProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function SelectParticipantScreen({
  handleSubmit
}: SelectParticipantScreenProps) {
  const classes = useStyles();
  const { user, signIn } = useAppState();
  const [authError, setAuthError] = useState<Error | null>(null);
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  //const [meetcode, setMeetcode] = useState("");
  // const [
  //   selectedParticipant,
  //   setSelectedParticipant
  // ] = useSelectedParticipant();
  const login = (e: FormEvent<HTMLFormElement>) => {
    setIsAuthInProgress(true);
    setAuthError(null);
    signIn
      ?.call(null, user?.meetcode, selectedParticipant)
      .then(() => {
        setIsAuthInProgress(false);
        handleSubmit(e);
        //history.replace(location?.state?.from || { pathname: "/" });
      })
      .catch(err => {
        setAuthError(err);
        setIsAuthInProgress(false);
      });
  };

  const [
    selectedParticipant,
    setSelectedParticipant
  ] = useSelectedParticipant();
  const internalSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(e);
    handleSubmit(e);
  };

  //const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Select
      </Typography>
      <form onSubmit={internalSubmitHandler}>
        <Grid container justify="space-between">
          <SelectParticipantList />
        </Grid>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!selectedParticipant || selectedParticipant.length === 0}
            className={classes.continueButton}
          >
            Submit
          </Button>
        </Grid>
      </form>
    </>
  );
}
