import React, { ChangeEvent, useState, FormEvent } from "react";
import { useAppState } from "../../state";

import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Grid from "@material-ui/core/Grid";
import { InputLabel, Theme } from "@material-ui/core";
import IntroContainer from "../IntroContainer/IntroContainer";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "120px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

export default function MeetCodePage() {
  const classes = useStyles();
  const { signIn, user, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [meetcode, setMeetcode] = useState("");
  const [authError, setAuthError] = useState<Error | null>(null);
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const login = () => {
    setIsAuthInProgress(true);
    setAuthError(null);
    signIn?.(meetcode)
      .then(() => {
        setIsAuthInProgress(false);
        //history.replace(location?.state?.from || { pathname: "/" });
      })
      .catch(err => {
        setAuthError(err);
        setIsAuthInProgress(false);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (!isAuthInProgress && (user || !isAuthEnabled)) {
    history.replace("/");
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <IntroContainer>
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Enter the Meeting Code to connect
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container justify="space-between">
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-meetcode">
                Meeting code
              </InputLabel>
              <TextField
                id="input-meetcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMeetcode(e.target.value)
                }
                type="text"
                variant="outlined"
                size="small"
              />
              <div>
                {authError && (
                  <Typography
                    variant="caption"
                    className={classes.errorMessage}
                  >
                    <ErrorOutlineIcon />
                    {authError.message}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!meetcode.length}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </form>
      </>
    </IntroContainer>
  );
}
