import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RoomType } from "../../types";
import { UserType } from "../index";

export function getCode() {
  const match = window.location.search.match(/meetcode=(.*)&?/);
  const code = match ? match[1] : null; //? match[1] : window.sessionStorage.getItem("meetcode");
  return code;
}

export function fetchToken(name: string, room: string, customer: string) {
  return fetch(
    `/api/token?name=${encodeURIComponent(name)}&room=${encodeURIComponent(
      room
    )}&customer=${encodeURIComponent(customer)}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  );
}

export function fetchMeetInfo(
  meetcode: string,
  name?: string,
  token?: boolean
) {
  if (name) {
    if (token) {
      return fetch(
        `/api/meet?hash=${encodeURIComponent(
          meetcode
        )}&name=${encodeURIComponent(name)}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json"
          }
        }
      );
    } else {
      return fetch(
        `/api/meet/info?hash=${encodeURIComponent(
          meetcode
        )}&name=${encodeURIComponent(name)}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json"
          }
        }
      );
    }
  } else {
    if (token) {
      return fetch(`/api/meet?hash=${encodeURIComponent(meetcode)}`, {
        method: "GET",
        headers: {
          "content-type": "application/json"
        }
      });
    } else {
      return fetch(`/api/meet/info?hash=${encodeURIComponent(meetcode)}`, {
        method: "GET",
        headers: {
          "content-type": "application/json"
        }
      });
    }
  }
}

export function verifyCode(meetcode: string, name?: string) {
  console.log(`verifyCode ${meetcode}, ${name}`);
  return fetchMeetInfo(meetcode, name).then(async res => {
    const jsonResponse = await res.json();
    if (res.status === 401 || res.status === 404 || jsonResponse?.error) {
      return { isValid: false, error: jsonResponse.error };
    }

    //if (res.ok && (jsonResponse.token || jsonResponse.participants)) {
    return { isValid: true, res: jsonResponse };
    //} else {
    //  return { isValid: false, res: { error: jsonResponse.error } };
    //}
  });
}

export function getErrorMessage(message: string) {
  switch (message) {
    case "code incorrect":
      return "Passcode is incorrect";
    case "code expired":
      return "Passcode has expired";
    default:
      return message;
  }
}

export default function useMeetcodeAuth() {
  const history = useHistory();

  const [user, setUser] = useState<UserType>();

  const [participants, setParticipants] = useState<string[]>();

  const [isAuthReady, setIsAuthReady] = useState(false);
  const [roomType, setRoomType] = useState<RoomType>();

  const [cachedToken, setCachedToken] = useState<string | undefined>();
  const [customer, setCustomer] = useState<string | undefined>();

  const getToken = useCallback(
    (name: string, room: string, passcode?: string) => {
      console.log(`getToken ${name}, ${room}, ${passcode}`);
      if (passcode && !participants) {
        //nacist ze stavu
        return fetchMeetInfo(passcode, name, true)
          .then(async res => {
            if (res.ok) {
              return res;
            }
            const json = await res.json();
            setCachedToken(undefined);
            const errorMessage = getErrorMessage(json.error || res.statusText);
            throw Error(errorMessage);
          })
          .then(res => res.json())
          .then(res => {
            setRoomType(res.roomType);
            //setRoom();
            setUser({
              displayName: name || "",
              meetcode: res.meetCode,
              roomName: res.room
            } as any);
            setCachedToken(res.token);
            return res.token as string;
          });
      } else {
        return fetchToken(name, room, customer || "")
          .then(async res => {
            if (res.ok) {
              return res;
            }
            const json = await res.json();

            const errorMessage = getErrorMessage(json.error || res.statusText);
            throw Error(errorMessage);
          })
          .then(res => res.json())
          .then(res => {
            return res.token as string;
          });
      }
    },
    [user]
  );

  useEffect(() => {
    const code = getCode();
    console.log(`getCode`);
    if (code) {
      verifyCode(code)
        .then(v => {
          if (v?.isValid) {
            if (v.res.customer) {
              setCustomer(v.res.customer);
              console.log(v.res.customer);
            }
            if (v.res.participants) {
              setParticipants(v.res.participants);
              if (v.res.participants.length <= 1 && v.res.identity) {
                setUser({
                  displayName: v.res.identity,
                  meetcode: v.res.hash,
                  roomName: v.res.room
                } as any);
              } else {
                setUser({
                  meetcode: v.res.hash,
                  roomName: v.res.room
                } as any);
              }
              history.replace(window.location.pathname);
            } else {
              setUser({
                displayName: v.res.identity,
                meetcode: v.res.hash,
                roomName: v.res.room
              } as any);
              //window.sessionStorage.setItem("meetcode", code);
              history.replace(window.location.pathname);
            }
          }
        })
        .then(() => setIsAuthReady(true));
    } else {
      setIsAuthReady(true);
    }
  }, [history]);

  const signIn = useCallback((passcode: string, name?: string) => {
    console.log(`signIn ${passcode}, ${name}`);
    return verifyCode(passcode, name).then(v => {
      if (v?.isValid) {
        setCustomer(v.res.customer);
        if (v.res.participants) {
          setParticipants(v.res.participants);
          if (v.res.participants.length <= 1 && v.res.identity) {
            setUser({
              displayName: v.res.identity,
              meetcode: passcode,
              roomName: v.res.room
            } as any);
          } else {
            setUser({
              displayName: name || "",
              meetcode: passcode,
              roomName: v.res.room
            } as any);
          }
        } else {
          setUser({
            displayName: v.res.identity,
            meetcode: passcode,
            roomName: v.res.room
          } as any);
        }

        //window.sessionStorage.setItem("meetcode", passcode);
      } else {
        throw new Error(getErrorMessage(v?.error));
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    setCachedToken(undefined);
    setCustomer(undefined);
    //window.sessionStorage.removeItem("meetcode");
    return Promise.resolve();
  }, []);

  return {
    user,
    setUser,
    isAuthReady,
    getToken,
    signIn,
    signOut,
    roomType,
    cachedToken,
    setCachedToken,
    customer,
    setCustomer,
    participants,
    setParticipants
  };
}
